import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const SectionsContainer = styled.div``

const Sections = () => (
  <SectionsContainer>
    <h3>
      <Link to="/dev-page">Developer Environment</Link>
    </h3>
    <p>Setting up your laptop is crucial to getting you up and running as a developer. Read through this section to help you get set up with a text editor, setting up a GitHub profile and a file organization pattern that will set you up for success during your time at DevMountain and beyond.</p>
    <h3>
      <Link to="/html-css-page">HTML & CSS</Link>
    </h3>
    <p>HTML & CSS are the stucture and style of your page. Think of HTML like the walls, foundation and roof of your home. CSS is the color of the house, the placement of the windows and front door style. Go through these videos and readings to get yoursel familiar with HTML & CSS.</p>
    <h3>
      <Link to="/js-page">JavaScript</Link>
    </h3>
    <p>Whether you are building a news, sports or blog website, JavaScript is crucial to making your site. This section will introduce you to the foundational topics of JavaScript and have you primed and ready to go come day one at DevMountain.</p>
  </SectionsContainer>
)

export default Sections